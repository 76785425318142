'use client'

import { Bars3Icon, XMarkIcon } from '@heroicons/react/20/solid'
import { BuildingLibraryIcon } from '@heroicons/react/24/outline'
import { Button, Collapse, ListItem, Menu, MenuHandler, MenuItem, MenuList, Typography } from '@material-tailwind/react'
import clsx from 'clsx'
import { useSession } from 'next-auth/react'
import { useLocale, useTranslations } from 'next-intl'
import Image from 'next/image'
import { useState } from 'react'

import { Link, usePathname } from '@/i18n'
import { useSchool } from '@/providers/SchoolProvider'
import cgnLogo from '@/public/images/cgn-logo.svg'

import { UserRoles } from '@roadmap/lib/services/firebase/firestore/enums'

import LanguageSwitcher from '.././LanguageSwitcher'
import AboutYou from '../AboutYou/AboutYou'

interface Props {
  availableSchools?: { [key: string]: string } | null
  availableRoles?: UserRoles[] | null
  availablePathways: { [key: string]: string[] }
}

const Header = (aviProps: Props) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false)
  const [isAdminMenuOpen, setIsAdminMenuOpen] = useState(false)
  const [isMegaMenuOpen, setIsMegaMenuOpen] = useState(false)
  const { school } = useSchool()
  const { data: session } = useSession()
  const t = useTranslations('NavigationBar')
  const tR = useTranslations('Roles')
  const locale = useLocale()
  const pathname = usePathname()
  const routesWithoutMenuItems = ['/onboarding']
  const displayMenuItems = routesWithoutMenuItems.includes(pathname) ? false : true
  const routesWithDashboardTitle = ['/home']
  const displayDashboardTitle = routesWithDashboardTitle.includes(pathname) ? true : false

  const currentPathDecoration = 'rounded-lg bg-gray-100 px-3 py-1 text-brand-purple-800 font-bold'
  const pathMatches = (path: string) => {
    const re = new RegExp(`^(\/${locale})?${path}$`)
    return re.test(pathname)
  }
  const counselorAdminMenuItems = [
    { path: '/counselor/school/guide', label: t('counselorGuideGettingStarted') },
    { path: '/counselor/school', label: t('schoolDetails') },
    { path: '/counselor/school/pathways', label: t('pathwaysAndAttributes') },
    { path: '/counselor/school/checklist', label: t('checklistItems') },
    { path: '/counselor/school/dates', label: t('keyDates') },
  ]
  if (!school?.disableCounselorReportingAndAdmin)
    counselorAdminMenuItems.push({ path: '/counselor/reports', label: t('reports') })
  const renderCounselorAdminMenuItems = (items: { path: string; label: string }[]) => {
    return items.map((item) => (
      <MenuItem key={item.path}>
        <Link
          href={item.path}
          className={clsx('font-bold', pathMatches(item.path) ? currentPathDecoration : 'md:text-black')}
          prefetch={false}
        >
          {item.label}
        </Link>
      </MenuItem>
    ))
  }

  const isAdminPath = () => {
    return counselorAdminMenuItems.some((item) => pathMatches(item.path))
  }
  const logoImageMobile = school?.cloudStorageLogoFile ? (
    <Image
      src={school.cloudStorageLogoFile}
      style={{ objectFit: 'contain' }}
      fill={true}
      alt={`School Logo`}
      sizes="(max-width: 768px) 100px, (max-width: 1200px) 150px, 100vw"
    />
  ) : (
    <BuildingLibraryIcon className="text-white" />
  )

  const schoolLogoImageMobile =
    session?.user?.role === UserRoles.COUNSELOR ? (
      <Link href={'/counselor/school'}>{logoImageMobile}</Link>
    ) : (
      logoImageMobile
    )

  const logoImage = school?.cloudStorageLogoFile ? (
    <div className="h-[56px] min-w-7">
      <Image
        src={school?.cloudStorageLogoFile}
        alt={`School Logo`}
        width={56}
        height={56}
        style={{ objectFit: 'contain', width: 'auto', height: '56px' }}
        sizes="(max-width: 768px) 100px, 
                 (max-width: 1200px) 150px, 
                 150px"
      />
    </div>
  ) : (
    <BuildingLibraryIcon className="text-white h-14 w-14" />
  )

  const schoolLogoImage =
    session?.user?.role === UserRoles.COUNSELOR ? <Link href={'/counselor/school'}>{logoImage}</Link> : logoImage

  return (
    <header className="w-full h-auto bg-brand-purple-800 px-4 md:px-10">
      <div
        className={`flex flex-row items-center ${session?.user ? 'max-w-3xl-custom mx-auto 3xl-custom:!px-0' : ''} justify-between `}
      >
        {/* CGN logo placement */}
        <div className="flex items-center py-2">
          <div
            className={clsx(
              'relative',
              session?.user
                ? 'lg:h-[4.3rem] lg:w-[11.5rem] h-[3rem] w-[7.35rem] sm-custom:h-[3.5rem] sm-custom:w-[9.35rem]'
                : 'h-[4.3rem] w-[11.5rem]'
            )}
          >
            {/* Logo */}
            <Image src={cgnLogo} fill={true} style={{ objectFit: 'contain' }} alt="College Guidance Network logo" />
          </div>
          {session?.user && displayMenuItems && displayDashboardTitle && (
            <div className="hidden lg:block ml-4 text-left text-xl lg:text-3xl text-white font-serif">
              <p className="leading-none">{tR(session?.user.role)}</p>
              <p className="leading-none">{t('dashboard')}</p>
            </div>
          )}
        </div>

        {session?.user && displayMenuItems && (
          <div className="flex items-center justify-end gap-2 lg:gap-4 mt-0 md:mt-3">
            <div className="hidden md:flex flex-row items-center gap-4 lg:gap-6 xl:gap-12 text-white font-bold sm:text-sm md:text-md lg:text-md mt-4">
              {/* Menu Items */}
              <Link href="/home" className={clsx(pathMatches('/home') && currentPathDecoration)} prefetch={false}>
                {t('home')}
              </Link>
              {session?.user?.role === UserRoles.COUNSELOR ? (
                <Menu allowHover={true}>
                  <MenuHandler>
                    <Button
                      className={clsx(
                        'bg-transparent normal-case text-sm font-bold py-0 px-0 focus:opacity-100',
                        isAdminPath() && currentPathDecoration
                      )}
                    >
                      {t('admin')}
                    </Button>
                  </MenuHandler>
                  <MenuList className="mt-1">{renderCounselorAdminMenuItems(counselorAdminMenuItems)}</MenuList>
                </Menu>
              ) : (
                <Link
                  href="/roadmap"
                  className={clsx(pathMatches('/roadmap') && currentPathDecoration)}
                  prefetch={false}
                >
                  {t('roadmap')}
                </Link>
              )}
              <Link
                href="/content-library"
                className={clsx(pathMatches('/content-library') && currentPathDecoration)}
                prefetch={false}
              >
                {t('resourceCenter')}
              </Link>
            </div>

            <div className="flex items-center gap-1 mt-3">
              <LanguageSwitcher />
              <AboutYou {...aviProps} />
              <div className="relative md:hidden md:h-7 md:w-7 w-6 h-6 ml-2 sm:mr-2 md:mr-0 md:ml-4 xl:ml-6">
                {schoolLogoImageMobile}
              </div>
              {/* Hamburger Menu */}
              <Button
                className="md:hidden text-white cursor-pointer bg-transparent rounded-full px-3"
                onClick={() => setIsMenuOpen(!isMenuOpen)}
              >
                {isMenuOpen ? (
                  <XMarkIcon className="md:h-7 md:w-7 w-6 h-6" />
                ) : (
                  <Bars3Icon className="md:h-7 md:w-7 w-6 h-6" />
                )}
              </Button>
            </div>

            {/* School specific logo */}
            <div
              className={clsx(
                'relative flex items-center justify-end mb-2', // Center align the logo
                session?.user ? 'hidden md:flex' : 'hidden'
              )}
            >
              {schoolLogoImage}
            </div>
          </div>
        )}
      </div>

      {isMenuOpen && (
        <div className="md:hidden bg-brand-purple-800">
          <div className="px-4 py-4 space-y-4 text-white font-bold">
            <div className="block">
              <Link
                href="/home"
                className={clsx(pathMatches('/home') && currentPathDecoration, 'inline-block')}
                prefetch={false}
              >
                {t('home')}
              </Link>
            </div>
            {session?.user?.role === UserRoles.COUNSELOR ? (
              <div className="space-y-0">
                <Menu open={isMegaMenuOpen} handler={setIsMegaMenuOpen} offset={{ mainAxis: 20 }} placement="bottom">
                  <MenuHandler>
                    <Typography as="div" variant="small" className="font-medium">
                      <ListItem
                        className={clsx(
                          'w-max normal-case text-base md:text-md font-bold p-0 mb-1',
                          isAdminPath()
                            ? clsx(
                                currentPathDecoration,
                                'active:text-brand-purple-800 focus:text-brand-purple-800 hover:text-brand-purple-800 hover:bg-opacity-100 active:bg-opacity-100 focus:bg-opacity-100'
                              )
                            : 'bg-transparent text-white active:bg-transparent active:text-white focus:bg-transparent focus:text-white hover:bg-transparent hover:text-white'
                        )}
                        selected={isMegaMenuOpen || isAdminMenuOpen}
                        onClick={() => setIsAdminMenuOpen((cur) => !cur)}
                      >
                        {t('admin')}
                      </ListItem>
                    </Typography>
                  </MenuHandler>
                </Menu>
                <div className="block lg:hidden">
                  <Collapse className="" open={isAdminMenuOpen}>
                    {renderCounselorAdminMenuItems(counselorAdminMenuItems)}
                  </Collapse>
                </div>
              </div>
            ) : (
              <div className="block">
                <Link
                  href="/roadmap"
                  className={clsx(pathMatches('/roadmap') && currentPathDecoration, 'inline-block')}
                  prefetch={false}
                >
                  {t('roadmap')}
                </Link>
              </div>
            )}
            <div className="inline-block">
              <Link
                href="/content-library"
                className={clsx(pathMatches('/content-library') && currentPathDecoration, 'inline-block')}
                prefetch={false}
              >
                {t('resourceCenter')}
              </Link>
            </div>
          </div>
        </div>
      )}
    </header>
  )
}

export default Header
